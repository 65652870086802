/* --------------------- Geral -------------------- */

#container {
    width: 95%;
    background: rgba(26, 38, 54, 0.9);
    color: #fff;
    margin: 20px auto;
    padding: 30px 20px;
    border-radius: 20px;
    box-shadow: 0px 0px 30px #3defce;
}

h1 {
    color: rgb(162, 201, 194);
}

h2 {
    color: #3defce;
}

p {
    font-size: 1.3rem;
}

/* ------------- Header ------------------ */

header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #3defce;
    margin-bottom: 20px;
}

#perfil-content{
    width: 33%;
    text-align: center;
}

#perfil {
    width: 200px;
    border-radius: 200px;
    border: 4px solid #0d6253;
}

#skills {
    width: 67%;
    text-align: center;
}

#allSkills {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

/* --------------------- section about-contact ---------------------- */
#about-contact {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #3defce;
}

#about-contact h1{
    text-align: center;
}

#about {
    width: 50%;
}

#about p {
    text-indent: 3ch;
}

#contact {
    width: 40%;
}

.buttons {
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
}

.buttons a, a, button {
    border: none;
    margin-right: 10px;
    margin-bottom: 10px;
    text-decoration: none;
    background: #0d6253;
    padding: 10px 20px 15px;
    border-radius: 10px;
    color: #fff;
}

.buttons a:hover, a:hover, button:hover {
    color: #3defce;
    box-shadow: 0px 0px 10px #3defce;
}

/* ------------ Projects ----------------- */
#projects{
    text-align: center;
    margin-top: 20px;
}

#projects nav {
    margin-top: 30px;
}

#projects ul {
    display: flex;
    flex-wrap: wrap;
}

#project-content{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    background: #0d6253;
    border-radius: 10px;
    padding: 30px;
    text-align: center;
}

#project-content h2 {
    font-size: 1.5rem;
    color: #fff;
}

#project-content img {
    width: 300px;
    margin-bottom: 20px;
}

#project-content a{
    border: 1px solid #fff;
}

.p-item{
    margin-bottom: 40px;
}